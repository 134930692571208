@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
  list-style: none;
}

.container {
  width: min(70rem, 100% - 2rem);
  margin-inline: auto;
}

.button {
  border: transparent;
  outline: transparents;
}
.button[datatype-button=primary] {
  margin-top: 2.5rem;
  margin-inline: auto;
  padding-block: 0.5rem;
  padding-inline: clamp(3rem, 10vw, 5rem);
  border-radius: 50vw;
  background-color: #004AAC;
  color: #ffffff;
  transition: background-color 200ms, color 200ms;
  cursor: pointer;
}
.button[datatype-button=primary]:hover {
  background-color: #ffffff;
  color: #004AAC;
}

section {
  padding-block: 6.25rem;
}

.hero-header, .cta-header {
  font-size: clamp(1.5rem, 0.5625rem + 3vw, 2.25rem);
  font-weight: 400;
  margin-inline: auto;
}
.hero-header span, .cta-header span {
  color: #004AAC;
}

.section-header {
  font-size: clamp(1rem, 0.375rem + 2vw, 1.5rem);
  margin-bottom: 5.3125rem;
}

.secondary-header {
  font-weight: 300;
  font-size: clamp(0.8125rem, 0.5781rem + 0.75vw, 1rem);
}

body {
  text-align: center;
}

.logo {
  margin-block: 1.5625rem;
  width: clamp(200px, 20vw, 340px);
}

.hero {
  background: url(../src/images/heroImg.jpg);
  background-size: cover;
  background-position: center;
  min-height: 70dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}
.hero .hero-header {
  margin-bottom: 2rem;
}
.hero .eyebrow {
  font-size: clamp(0.4375rem, 0.2031rem + 0.75vw, 0.625rem);
  font-weight: 300;
}
.hero span {
  color: #004AAC;
  font-weight: bold;
}

.our-process ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}
.our-process ul div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.75rem;
}
.our-process ul div img {
  width: min(42px, 9vw);
}
.our-process ul div .secondary-header {
  font-weight: 700;
}
.our-process ul .description {
  font-size: clamp(0.625rem, 0.3906rem + 0.75vw, 0.8125rem);
  margin-left: 3rem;
  text-align: left;
}

.about-us {
  background-color: #2C2C2C;
  color: #ffffff;
}
.about-us body {
  max-width: 82ch;
}

.cta {
  background-image: url(../src/images/ctaImg.jpg);
  background-size: cover;
  background-position: center;
  color: #ffffff;
  padding-block: 8rem;
}
.cta .secondary-header span {
  color: #004AAC;
}

.featured ul {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  margin-inline: 1rem;
}
.featured ul li {
  flex-grow: 99999;
  flex-flow: row;
}

.footer {
  background-color: #2C2C2C;
  padding-block: 3rem;
  color: #ffffff;
}
.footer .copyright {
  font-size: clamp(0.4375rem, 0.2031rem + 0.75vw, 0.625rem);
}

.popup {
  padding-block: 2rem;
  min-height: 50vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(255, 255, 255, 0.31);
}
.popup button {
  width: 100%;
}
.popup form {
  width: 520px;
  max-width: 100%;
  padding-inline: min(5vw, 2.75rem);
  display: grid;
  align-self: center;
}
.popup form .input-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.popup form label {
  text-align: left;
  font-size: clamp(0.625rem, 0.3906rem + 0.75vw, 0.8125rem);
}
.popup form input, .popup form textarea {
  height: 2.1875rem;
  padding-left: 0.5rem;
  border: transparent;
  outline: 1px solid #004AAC;
  border-radius: 7px;
}
.popup form textarea {
  padding-top: 0.5rem;
  resize: none;
  min-height: 11.25rem;
}
.popup form .name {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: min(2rem, 2.5vw);
}/*# sourceMappingURL=App.css.map */