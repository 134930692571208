@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

$clr-primary-400: #004AAC;
$clr-primary-800: #0080AB;
$clr-light-400: #ffffff;
$clr-dark-400: #2C2C2C;

$fs-100: .4375rem;
$fs-200: clamp(0.4375rem, 0.2031rem + 0.75vw, 0.625rem);
$fs-300: clamp(0.625rem, 0.3906rem + 0.75vw, 0.8125rem);
$fs-400: clamp(0.8125rem, 0.5781rem + 0.75vw, 1rem);
$fs-500: clamp(1rem, 0.375rem + 2vw, 1.5rem);
$fs-600: clamp(1.5rem, 0.5625rem + 3vw, 2.25rem);
$fs-700: clamp(2.25rem, 1.3125rem + 3vw, 3rem);

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

$ff-primary:'poppins', sans-serif;

$breakpoint-small: 50em;
$breakpoint-medium: 65rem;
$breakpoint-large: 90rem;

$screen-max-width: 70rem;

html{
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $ff-primary;
  list-style: none;
}
//utility classes
.container{
  width: min($screen-max-width, 100% - 1rem *  2);
  margin-inline: auto;
}
.button{
  border: transparent;
  outline: transparents;
  &[datatype-button='primary']{
    margin-top: 2.5rem;
    margin-inline: auto;
    padding-block: .5rem;
    padding-inline: clamp(3rem, 10vw, 5rem);
    border-radius: 50vw;
    background-color: $clr-primary-400;
    color: $clr-light-400;
    transition: background-color  200ms, color 200ms;
    cursor: pointer;
    &:hover{
      background-color: $clr-light-400;
      color: $clr-primary-400;
    }
  }
}
section{
  padding-block: 6.25rem;
}
//typography
.hero-header, .cta-header{
  font-size: $fs-600;
  font-weight: $fw-regular;
  margin-inline: auto;
  span{
    color: $clr-primary-400;
  }
}
.section-header{
  font-size: $fs-500;
  margin-bottom: 5.3125rem;
}
.secondary-header{
  font-weight: $fw-light;
  font-size: $fs-400;
}

body{
  text-align: center;
}

.logo{
  margin-block: 1.5625rem;
  width: clamp(200px, 20vw, 340px);
}
.hero{
  background: url(../src/images/heroImg.jpg);
  background-size: cover;
  background-position: center;
  min-height: 70dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: $clr-light-400;

  .hero-header{
    margin-bottom: 2rem;
  }
  .eyebrow{
    font-size: $fs-200;
    font-weight: $fw-light;
  }
  span{
    color: $clr-primary-400;
    font-weight: bold;
  }
}

.our-process{
  ul{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    div{
      display: flex;
      gap: .5rem;
      align-items: center;
      margin-bottom: .75rem;
      img{
        width: min(42px, 9vw );
      }
      .secondary-header{
        font-weight: $fw-bold;
      }
      }
      .description{
        font-size: $fs-300;
        margin-left:3rem;
        text-align: left;
      }
  }
}
.about-us{
  background-color: $clr-dark-400;
  color: $clr-light-400;
  body{
    max-width: 82ch;
  }
}
.cta{
  background-image: url(../src/images/ctaImg.jpg);
  background-size: cover;
  background-position: center;
  color: $clr-light-400;
  padding-block: 8rem;
  .secondary-header{
    span{

      color: $clr-primary-400;
    }
  }
}
.featured{
  ul{
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    margin-inline: 1rem;
    li{
      flex-grow: 99999;
      flex-flow: row;
    }
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // gap: 2rem;
    
  }
}
.footer{
  background-color: $clr-dark-400;
  padding-block: 3rem;
  color: $clr-light-400;
  .copyright{
    font-size: $fs-200;
  }
}

.popup{
  padding-block: 2rem;
  min-height: 50vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);
  border: 1px solid rgba(255, 255, 255, 0.31);
  button{
    width: 100%;
  }
  form{
    width: 520px;
    max-width: 100%;
    padding-inline: min(5vw, 2.75rem) ;
    display: grid;
    align-self: center;
    .input-wrapper{
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    label{
      text-align: left;
      font-size: $fs-300;
    }
    input, textarea{
      height: 2.1875rem;
      padding-left: .5rem;
      border: transparent;
      outline: 1px solid $clr-primary-400;
      border-radius: 7px;
    }
    textarea{
      padding-top: .5rem;
      resize: none;
      min-height: 11.25rem;
    }
    .name{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: min(2rem, 2.5vw);
    }
  }
}